// Using it as a body for a payment modal window that we use in TeamTournStatusPaid

import { useState, useEffect } from 'react';
import { useFirebase } from './FirebaseContextProvider';
import { useFormik } from 'formik';
import { squareCreatePaymentLink } from '../utils/firebase';
import * as Yup from 'yup';
import Input from './Input';
import Spinner from './Spinner';
import { PaymentPurposes, Currencies } from '../utils/enums';
import './PaymentModalBody.scss';

export default function PaymentModalBody({ team, tournament, resetFormFlag }) {
  const [payLink, setPayLink] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [err, setErr] = useState(null);
  const { teams, tournaments } = useFirebase();

  const teamName = teams.find((t) => t.id === team)?.name;
  const tournamentName = tournaments.find(
    (tourn) => tourn.id === tournament
  )?.name;

  const initialValues = {
    amount: '',
    submitAction: '',
  };

  const validationSchema = new Yup.ObjectSchema({
    amount: Yup.number()
      .typeError('Must be a number')
      .positive('Must be positive')
      .required('Required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        setSpinner(true);

        const res = await squareCreatePaymentLink({
          amount: +values.amount,
          currency: Currencies.USD,
          description: `Payment for the ${teamName} team to participate in the ${tournamentName} tournament`,
          purpose: PaymentPurposes.TOURNAMENT,
          details: {
            team,
            tournament,
          },
          redirectUrl: `${process.env.REACT_APP_ROOT_URL}/payment-success`,
        });
        const paylink = res.data;

        if (values.submitAction === 'pay') {
          window.location.href = paylink;
          return;
        }
        if (values.submitAction === 'generateLink') setPayLink(paylink);
        setSpinner(false);
      } catch (error) {
        setErr(error.message);
        setSpinner(false);
      }
    },
  });

  useEffect(() => {
    formik.resetForm();
    setPayLink(null);
    setErr(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFormFlag]);

  return (
    <form className='payment-modal-body' onSubmit={formik.handleSubmit}>
      <p className='font-16'>
        {`Please make a payment for the `}
        <span className='font-medium'>{teamName}</span>
        {` team to participate in the `}
        <span className='font-medium'>{tournamentName}</span>
        {` tournament`}
      </p>
      <div className='amount-pay-wrap'>
        <Input
          name='amount'
          placeholder='$ Amount'
          value={formik.values.amount ? '$ ' + formik.values.amount : ''}
          onChange={(e) => {
            let val = e.target.value;
            if (val.length >= 2) val = val.slice(2);
            formik.setFieldValue('amount', val);
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.amount && formik.errors.amount
              ? formik.errors.amount
              : null
          }
        />
        <button
          type='submit'
          name='submitAction'
          value='pay'
          onClick={() => formik.setFieldValue('submitAction', 'pay')}
        >
          Pay now
        </button>
      </div>
      <div className={'err-msg-wrap' + (err ? ' show' : '')}>
        <p className='font-16 danger'>{err}</p>
      </div>
      <button
        className='small bw'
        type='submit'
        name='submitAction'
        value='generateLink'
        onClick={() => {
          setErr(null);
          formik.setFieldValue('submitAction', 'generateLink');
        }}
      >
        Generate payment link to send
      </button>
      <div className={'pay-link-wrap' + (payLink ? ' show' : '')}>
        <p className='font-16'>
          Please share the link below with the person you wish to request
          payment from:
          <br />
          <br />
          <span
            className='primary font-medium'
            style={{ wordBreak: 'break-all' }}
          >
            {payLink}
          </span>
          <br />
          <br />
          Please note that each payment link is single-use only. If you need to
          share a payment link with multiple people, make sure to generate the
          appropriate number of links
        </p>
      </div>
      <Spinner show={spinner} />
    </form>
  );
}
