// Team page

import { useLoaderData } from 'react-router-dom';
import Layout from '../components/Layout';
import TeamCard from '../components/TeamCard';
import Spinner from '../components/Spinner';

export default function PageTeam() {
  const team = useLoaderData();

  return (
    <Layout className='page-team'>
      {!team.loading && <TeamCard team={team} />}
      <Spinner show={team.loading} />
    </Layout>
  );
}
