// Spinner to show during async operations

import ClipLoader from 'react-spinners/ClipLoader';
import './Spinner.scss';

export default function Spinner({ show }) {
  return (
    <div className={'spinner' + (show ? ' show' : '')}>
      <ClipLoader size={80} color='#3f90dc' loading={show} />
    </div>
  );
}
