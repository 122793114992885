// Common snippet for TeamsList and TournamentsList

import { useState } from 'react';
import PaymentModalBody from './PaymentModalBody';
import { useFirebase } from './FirebaseContextProvider';
import { tournamentSetTeamStatus } from '../utils/firebase';
import { useModal } from './ModalProvider';
import { UsersRoles, TournTeamStatuses, PaymentPurposes } from '../utils/enums';
import './TeamTournStatusPaid.scss';

function Status({
  status,
  className = '',
  onInvite,
  onAccept,
  onReject,
  onPay,
}) {
  const { user } = useFirebase();
  const isCoach = user.role === UsersRoles.COACH;

  switch (status) {
    case TournTeamStatuses.ACCEPTED:
      return (
        <div className={className}>
          <p className='success upper font-medium'>{status}</p>
          {isCoach && (
            <button
              className='small bw'
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onPay();
              }}
            >
              Pay
            </button>
          )}
        </div>
      );
    case TournTeamStatuses.REJECTED:
      return (
        <p className={'danger upper font-medium ' + className}>{status}</p>
      );
    case TournTeamStatuses.INVITED:
      return (
        <div className={className}>
          <p className='upper font-medium'>{status}</p>
          {isCoach && (
            <div className='btn-wrap'>
              <button className='small bw' onClick={onAccept}>
                Accept
              </button>
              <button className='small danger' onClick={onReject}>
                Reject
              </button>
            </div>
          )}
        </div>
      );
    default:
      return (
        <div className={className}>
          <button className='small bw' onClick={onInvite}>
            Invite
          </button>
        </div>
      );
  }
}

export default function TeamTournStatusPaid({ team, tournament, setSpinner }) {
  const [resetFormFlag, setResetFormFlag] = useState(false);
  const { user, tournaments, payments } = useFirebase();
  const { openModal } = useModal();

  const status = tournaments
    .find((tourn) => tourn.id === tournament)
    ?.teams.find((t) => t.id === team)?.status;

  const paid = payments.reduce(
    (accum, payment) =>
      payment.purpose === PaymentPurposes.TOURNAMENT &&
      payment.details.team === team &&
      payment.details.tournament === tournament
        ? accum + payment.amount
        : accum,
    0
  );

  const isCoach = user.role === UsersRoles.COACH;
  const isInvited = status === TournTeamStatuses.INVITED;
  const isAccepted = status === TournTeamStatuses.ACCEPTED;

  const onSetTeamStatus = async (e, status) => {
    e.stopPropagation();
    e.preventDefault();

    try {
      setSpinner(true);
      await tournamentSetTeamStatus({
        id: tournament,
        team,
        status,
      });
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.error(error.message);
    }
  };

  const onPay = () => {
    setResetFormFlag((curFlag) => !curFlag);
    openModal({
      header: 'Payment',
      body: (
        <PaymentModalBody
          team={team}
          tournament={tournament}
          resetFormFlag={resetFormFlag}
        />
      ),
    });
  };

  return (
    <div className='team-tourn-status-paid'>
      <Status
        status={status}
        className={'status' + (isInvited && isCoach ? ' invited' : '')}
        onInvite={(e) => onSetTeamStatus(e, TournTeamStatuses.INVITED)}
        onAccept={(e) => onSetTeamStatus(e, TournTeamStatuses.ACCEPTED)}
        onReject={(e) => onSetTeamStatus(e, TournTeamStatuses.REJECTED)}
        onPay={onPay}
      />
      <p className={'paid font-medium' + (isAccepted ? ' show' : '')}>
        {'$' + (paid ? paid : 0)}
      </p>
    </div>
  );
}
