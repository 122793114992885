// Common visual element

export default function PropItem({ prop, value }) {
  return (
    <p className='prop-item'>
      <span className='gray-500 font-medium'>{prop}: </span>
      {value}
    </p>
  );
}
