// Page for users with the 'none' role

import { useLoaderData } from 'react-router-dom';
import Layout from '../components/Layout';
import NoneCard from '../components/NoneCard';
import Spinner from '../components/Spinner';

export default function PageNone() {
  const none = useLoaderData();

  return (
    <Layout className='page-none'>
      {!none.loading && <NoneCard none={none} />}
      <Spinner show={none.loading} />
    </Layout>
  );
}
