// Edition form for coach card

import * as Yup from 'yup';
import CardEditTemplate from './CardEditTemplate';
import isMobilePhone from 'validator/lib/isMobilePhone';
import Input from './Input';
import { userUpdate } from '../utils/firebase';
import { emailRegExp } from '../utils/misc';
import './CoachCardEdit.scss';

export default function CoachCardEdit({ coach }) {
  const { id, name, phone, email, imgUrl, imgThumbUrl } = coach;

  const initialValues = {
    name: name || '',
    phone: phone || '',
    email: email || '',
  };

  const validationSchema = new Yup.ObjectSchema({
    name: Yup.string().max(100, 'Too long').required('Required'),
    phone: Yup.string()
      .required('Required')
      .test('is-valid-phone', 'Invalid', (value) =>
        value ? isMobilePhone(value, 'en-US', { strictMode: false }) : false
      ),
    email: Yup.string().matches(emailRegExp, 'Invalid').required('Required'),
  });

  return (
    <CardEditTemplate
      className='coach-card-edit'
      id={id}
      imgUrl={imgUrl}
      imgThumbUrl={imgThumbUrl}
      imgPath={`users/${id}/`}
      formConfig={{
        initialValues,
        validationSchema,
      }}
      update={userUpdate}
      create={() => {}}
      afterCreateNavPath={`/coaches/${id}#teams`}
    >
      {(formik) => (
        <>
          <Input
            name='name'
            placeholder='Name'
            {...formik.getFieldProps('name')}
            error={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : null
            }
          />
          <div className='contacts-wrap'>
            <Input
              name='phone'
              placeholder='Phone'
              {...formik.getFieldProps('phone')}
              error={
                formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : null
              }
            />
            <Input
              name='email'
              placeholder='e-mail'
              {...formik.getFieldProps('email')}
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
          </div>
        </>
      )}
    </CardEditTemplate>
  );
}
