// Showing an image as a full-screen modal window

import { Link } from 'react-router-dom';
import './ImageModal.scss';

export default function ImageModal({
  show,
  image,
  imgUrl,
  title,
  onClose,
  downloadable,
}) {
  return (
    <div className={'image-modal modal' + (show ? ' show' : '')}>
      <div
        className='bg-image'
        style={{
          backgroundImage: `url(${imgUrl})`,
        }}
      />
      <h4 className='title'>{title}</h4>
      <button className='close-btn white small' onClick={onClose} type='button'>
        &#xf00d;
      </button>
      {downloadable && (
        <Link
          to={imgUrl}
          target='_blank'
          onClick={(e) => e.stopPropagation()}
          className='download-btn button bw small'
          download={image}
        >
          Download
        </Link>
      )}
    </div>
  );
}
