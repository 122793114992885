// User with the 'none' role details interface

import { useFirebase } from './FirebaseContextProvider';
import CardTemplate from './CardTemplate';
import Tabs from './Tabs';
import Gallery from './Gallery';
import PropItem from './PropItem';
import PropItemsBlock from './PropItemsBlock';
import NoneCardEdit from './NoneCardEdit';
import { userUpdate } from '../utils/firebase';
import { DocTypes } from '../utils/enums';

export default function NoneCard({ none }) {
  const { user } = useFirebase();

  const { id, imgUrl, imgThumbUrl, name, phone, email, docs } = none;
  const isTheUser = user.id === id;

  const onUploadImg = async (file, index, type) => {
    try {
      const newDocs = docs.map(({ image, type }) => ({ image, type }));

      const doc = { image: file.name, type: type ? type : DocTypes.OTHER };

      if (index !== undefined) {
        newDocs[index] = doc;
      } else newDocs.push(doc);

      await userUpdate({ id, docs: newDocs });
    } catch (error) {
      console.error(error.message);
    }
  };

  const onDeleteImg = async (index) => {
    try {
      const newDocs = docs
        .map(({ image, type }) => ({ image, type }))
        .filter((doc, i) => i !== index);

      await userUpdate({ id, docs: newDocs });
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <CardTemplate
      header='None role'
      className='none-card'
      imgUrl={imgUrl}
      imgThumbUrl={imgThumbUrl}
      imgTitle={name}
      name={name}
      details={
        <PropItemsBlock>
          <PropItem prop='Phone' value={phone} />
          <PropItem prop='e-mail' value={email} />
          <p className='font-16 font-medium'>
            You need an invitation to get a role
            <br />
            Please ask for an invitation from your coach or administrator
          </p>
        </PropItemsBlock>
      }
      editForm={isTheUser && <NoneCardEdit none={none} />}
    >
      <Tabs
        className='none-card-tabs'
        tabs={[
          {
            title: 'Documents',
            hash: '#documents',
            component: (
              <div className='block'>
                <Gallery
                  images={docs.map((doc) => ({
                    title: doc.type,
                    thumbUrl: doc.imgThumbUrl,
                    url: doc.imgUrl,
                    path: `users/${id}/`,
                  }))}
                  newImgUploadPath={`users/${id}/`}
                  onUploadImg={isTheUser ? onUploadImg : undefined}
                  onDeleteImg={isTheUser ? onDeleteImg : undefined}
                  types={[
                    DocTypes.ID,
                    DocTypes.BIRTHCERTIFICATE,
                    DocTypes.SCHOOLREPORTCARD,
                    DocTypes.CERTIFICATE,
                    DocTypes.LICENSE,
                    DocTypes.OTHER,
                  ]}
                />
              </div>
            ),
          },
        ]}
      />
    </CardTemplate>
  );
}
