// User see this following an invitation link

import { useState, useEffect } from 'react';
import { useFirebase } from './FirebaseContextProvider';
import { useUserNavigate } from '../utils/hooks';
import Card from './Card';
import { userUseToken, tokenGet } from '../utils/firebase';
import { useModal } from './ModalProvider';
import './InviteCard.scss';

export default function InviteCard({ token }) {
  const [spinner, setSpinner] = useState(true);
  const [err, setErr] = useState(null);
  const [role, setRole] = useState();
  const [teamName, setTeamName] = useState();
  const [applied, setApplied] = useState();
  const [redirect, setRedirect] = useState(false);
  const { user, isUsersLoaded } = useFirebase();
  const { openModal } = useModal();

  useEffect(() => {
    const getTokenData = async () => {
      try {
        setSpinner(true);
        const res = await tokenGet({ id: token, userId: user?.id });
        const { teamName, role, applied } = res.data;
        setTeamName(teamName);
        setRole(role);
        setApplied(applied);
        setSpinner(false);
      } catch (error) {
        const errorMessage =
          error?.message || `Error: unable to read token ${token}`;
        setErr(errorMessage);
        setSpinner(false);
      }
    };

    getTokenData();
  }, [token, user?.id]);

  useUserNavigate({ redirect, token });

  // If there is no auth, navigate to auth page
  useEffect(() => {
    if (isUsersLoaded && !user) setRedirect(true);
  }, [user, isUsersLoaded]);

  const applyToken = async () => {
    try {
      setSpinner(true);
      await userUseToken({ id: user.id, token });
      setSpinner(false);

      await new Promise((res) => {
        openModal({
          header: 'Invitation is accepted',
          body: (
            <p className='font-16'>
              The invitation has been successfully accepted
            </p>
          ),
          buttons: [
            {
              text: 'Ok',
              onClick: res,
            },
          ],
        });
      });

      setRedirect(true);
    } catch (error) {
      setSpinner(false);
      setErr(error.message);
    }
  };

  return (
    <Card header='Accept invitation' className='invite-card' spinner={spinner}>
      {applied && (
        <p className='invitation-text font-16'>
          You have accepted the invitation already
        </p>
      )}
      {!applied && teamName && role && (
        <p className='invitation-text font-16'>
          {`You are invited to the `}
          <span className='font-medium'>{teamName}</span>
          {` team as a `}
          <span className='font-medium'>{role}</span>.
          <br />
          {`Please, press the `}
          <span className='font-medium'>OK</span>
          {` button to accept the invitation.`}
        </p>
      )}
      {!applied && !teamName && !role && (
        <p className='invitation-text font-16'>Loading token data...</p>
      )}
      <p className={'error-msg' + (err ? ' show' : '')}>{err}</p>
      <div className='btns-wrap'>
        {applied && <button onClick={() => setRedirect(true)}>Ok</button>}
        {!applied && (
          <>
            <button className='bw' onClick={() => setRedirect(true)}>
              Cancel
            </button>
            <button onClick={applyToken}>Ok</button>
          </>
        )}
      </div>
    </Card>
  );
}
