// Page user redirecting after a successful payment

import Layout from '../components/Layout';
import PaymentSuccessCard from '../components/PaymentSuccessCard';

export default function PagePaymentSuccess() {
  return (
    <Layout screenSized className='page-payment-success'>
      <PaymentSuccessCard />
    </Layout>
  );
}
