// Main page template wrapping each page

import ScrollToTop from './ScrollToTop';
import User from './User';
import ScrollToTopButton from './ScrollToTopButton';
import './Layout.scss';

export default function Layout({ screenSized, className, children }) {
  return (
    <>
      <ScrollToTop />
      <ScrollToTopButton />
      <div
        className={
          'layout' +
          (screenSized ? ' screen-sized' : '') +
          (className ? ' ' + className : '')
        }
      >
        <section className='layout-header'>
          <div className='container'>
            <h3>Muva Sport</h3>
            <User />
          </div>
        </section>
        <section className='layout-body'>
          <div className='container'>{children}</div>
        </section>
      </div>
    </>
  );
}
