import moment from 'moment';
import { imgThumbPostfix } from './consts';

// Regular expression to check a email
export const emailRegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

// Date range formater
export function formatDateRange(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);

  if (start.isSame(end, 'year')) {
    if (start.isSame(end, 'month')) {
      if (start.isSame(end, 'day')) {
        return start.format('MMM D, YY');
      } else {
        return `${start.format('MMM D')}-${end.format('D, YY')}`;
      }
    } else {
      return `${start.format('MMM D')} – ${end.format('MMM D, YY')}`;
    }
  } else {
    return `${start.format('MMM D, YY')} – ${end.format('MMM D, YY')}`;
  }
}

// Add thumbnail postfix to filename
export function addThumbnailPostfix(filename) {
  if (!filename) return '';

  const dotIndex = filename.lastIndexOf('.');
  if (dotIndex === -1) return filename + imgThumbPostfix;

  const name = filename.slice(0, dotIndex);
  const extension = filename.slice(dotIndex);

  return `${name}${imgThumbPostfix}${extension}`;
}

// Finds the key corresponding to a given value in an object
export const getKeyByValue = (obj, value) => {
  const entry = Object.entries(obj).find(([_, v]) => v === value);
  return entry ? entry[0] : undefined;
};
