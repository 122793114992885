// Player's docs checklist visualisation

import MarkedText from './MarkedText';
import { DocTypes } from '../utils/enums';
import './PlayersDocsChecklist.scss';

export default function PlayersDocsChecklist({ docs }) {
  const id = docs.some((doc) => doc.type === DocTypes.ID);
  const birthCertificate = docs.some(
    (doc) => doc.type === DocTypes.BIRTHCERTIFICATE
  );
  const schoolReportCard = docs.some(
    (doc) => doc.type === DocTypes.SCHOOLREPORTCARD
  );

  return (
    <div className='players-docs-checklist'>
      <MarkedText mark={id} text='ID' />
      <MarkedText mark={birthCertificate} text='Birth certificate' />
      <MarkedText mark={schoolReportCard} text='Report card' />
    </div>
  );
}
