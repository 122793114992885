// Payments list

import moment from 'moment';
import { PaymentPurposes } from '../utils/enums';
import { useFirebase } from './FirebaseContextProvider';
import './PaymentsList.scss';

function Col12({ date, id }) {
  return (
    <>
      <p className='col-1'>{moment(date).format('MMM D, YYYY')}</p>
      <p className='col-2'>{id}</p>
    </>
  );
}

export default function PaymentsList({ userId }) {
  const { payments, teams, tournaments, users } = useFirebase();

  return (
    <div className='payments-list'>
      <ul className='header tab-header'>
        <li className='col-1'>Date</li>
        <li className='col-2'>Payment&nbsp;ID</li>
        <li className='col-12'>Date, Payment&nbsp;ID</li>
        <li className='col-3'>Description</li>
        <li className='col-4'>Amount</li>
      </ul>
      {payments
        .filter((payment) => {
          if (!userId) return true;

          if (
            users
              .find((user) => user.id === userId)
              ?.teams?.includes(payment.details.team)
          )
            return true;

          return false;
        })
        .map((payment) => {
          let description = '';
          switch (payment.purpose) {
            case PaymentPurposes.TOURNAMENT:
              description = (
                <>
                  For the{' '}
                  <span className='font-medium'>
                    {
                      teams.find((team) => team.id === payment.details.team)
                        ?.name
                    }
                  </span>{' '}
                  team to participate in the{' '}
                  <span className='font-medium'>
                    {
                      tournaments.find(
                        (tournament) =>
                          tournament.id === payment.details.tournament
                      )?.name
                    }
                  </span>{' '}
                  tournament
                </>
              );
              break;
            case PaymentPurposes.CARD:
              description = "Player's card";
              break;
            default:
              break;
          }

          return (
            <div className='payment' key={payment.id}>
              <Col12 date={payment.time.toDate()} id={payment.id} />
              <div className='col-12'>
                <Col12 date={payment.date} id={payment.id} />
              </div>
              <p className='col-3'>{description}</p>
              <p className='col-4 font-medium'>{'$' + payment.amount}</p>
            </div>
          );
        })}
    </div>
  );
}
