// Component to show images

import { useEffect, useState } from 'react';
import ImageBtns from './ImageBtns';
import './Gallery.scss';

export default function Gallery({
  images,
  newImgUploadPath,
  onUploadImg,
  onDeleteImg,
  types,
}) {
  const [newImgUrl, setNewImgUrl] = useState();

  // Those two useEffects below is to switch off spinner on the new images loader
  useEffect(() => {
    setNewImgUrl('');
  }, [images.length]);

  useEffect(() => {
    if (newImgUrl === '') setNewImgUrl(undefined);
  }, [newImgUrl]);

  return (
    <div className='gallery'>
      {images.map((img, i) => (
        <ImageBtns
          key={i}
          imgUrl={img.url}
          imgThumbUrl={img.thumbUrl}
          imgTitle={img.title}
          showTitleOnThumb
          imgPath={img.path}
          size={256}
          clickable
          downloadable
          onUploadImg={
            onUploadImg ? (file, type) => onUploadImg(file, i, type) : undefined
          }
          onDeleteImg={onDeleteImg ? () => onDeleteImg(i) : undefined}
          types={types}
        />
      ))}
      {onUploadImg && (
        <ImageBtns
          imgPath={newImgUploadPath}
          size={256}
          onUploadImg={
            onUploadImg
              ? (file, type) => onUploadImg(file, undefined, type)
              : undefined
          }
          imgUrl={newImgUrl}
          types={types}
        />
      )}
    </div>
  );
}
