// Card component

import Spinner from './Spinner';
import './Card.scss';

export default function Card({
  header,
  className,
  children,
  btnText,
  onClick,
  btnLeftArrow,
  btnCross,
  spinner = false,
}) {
  return (
    <div className={'card' + (className ? ' ' + className : '')}>
      <div className='card-top'>
        <h5 className='header'>{header}</h5>
        {onClick && (
          <button
            className={
              'right-button white small' +
              (btnLeftArrow ? ' fa-left-arrow' : '') +
              (btnCross ? ' fa-cross' : '')
            }
            onClick={onClick}
          >
            {btnText ?? ''}
          </button>
        )}
      </div>
      <div className='card-body'>
        {children}
        <Spinner show={spinner} />
      </div>
    </div>
  );
}
