// Card with some congratulations about a payment

import { useState } from 'react';
import Card from './Card';
import { useUserNavigate } from '../utils/hooks';
import './PaymentSuccessCard.scss';

export default function PaymentSuccessCard() {
  const [redirect, setRedirect] = useState(false);
  useUserNavigate({ redirect });

  return (
    <Card header='Payment successful' className='payment-success-card'>
      <p className='font-16'>Your payment has been processed successfully</p>
      <button onClick={() => setRedirect(true)}>Ok</button>
    </Card>
  );
}
