// Customized DatePicker component

import { useRef } from 'react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './InputDate.scss';

export default function InputDate({
  value,
  onChange,
  minDate,
  maxDate,
  className,
  placeholder,
  error,
}) {
  const wrapRef = useRef(null);

  const handleOpenCalendar = (e) => {
    if (!wrapRef.current) return;
    if (e.target.closest('.react-calendar')) return;

    const calendarButton = wrapRef.current.querySelector(
      '.react-date-picker__calendar-button'
    );

    if (calendarButton) calendarButton.click();
  };

  return (
    <div
      className={
        'date-picker-wrap' +
        (className ? ' ' + className : '') +
        (value ? '' : ' placeholder pointer') +
        (error ? ' error' : '')
      }
      onClick={value ? undefined : handleOpenCalendar}
      ref={wrapRef}
    >
      <DatePicker
        value={value}
        onChange={onChange}
        format='MMM d, y'
        clearIcon={null}
        minDate={minDate}
        maxDate={maxDate}
      />
      <p className='placeholder-text'>{placeholder}</p>
      <p className={'error-msg' + (error ? ' visible' : '')}>{error}</p>
    </div>
  );
}
