// Module to contain react hooks

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirebase } from '../components/FirebaseContextProvider';
import { UsersRoles } from './enums';

// Here is the logic to redirect authorized users to their root path
export const useUserNavigate = (data) => {
  const navigate = useNavigate();
  const { user, isUsersLoaded } = useFirebase();

  const { redirect = true, token } = data || {};

  useEffect(() => {
    if (!redirect || !isUsersLoaded) return;

    let redirectUrl = `/auth${token ? '/' + token : ''}`;

    switch (user?.role) {
      case UsersRoles.ADMIN:
        redirectUrl = '/admin';
        break;
      case UsersRoles.COACH:
        redirectUrl = '/coaches/' + user.id;
        break;
      case UsersRoles.PLAYER:
        redirectUrl = '/players/' + user.id;
        break;
      case UsersRoles.NONE:
        redirectUrl = '/none/' + user.id;
        break;
      default:
        break;
    }

    navigate(redirectUrl);
  }, [user, isUsersLoaded, navigate, redirect, token]);
};
