// A custom select element that appears without an input field, kinda from nowhere

import GetClickOutside from './GetClickOutside';
import './SelectInline.scss';

export default function SelectInline({
  className,
  header,
  options,
  small,
  show,
  onChange,
  onClose,
}) {
  return (
    <div
      className={
        'select-inline' +
        (small ? ' small' : '') +
        (show ? ' show' : '') +
        (className ? ' ' + className : '')
      }
    >
      <GetClickOutside active={show} onClick={onClose}>
        <p className='header'>{header}</p>
        <ul className='options'>
          {options.map((option, index) => (
            <li key={index} onClick={() => onChange(option)}>
              {option}
            </li>
          ))}
        </ul>
      </GetClickOutside>
    </div>
  );
}
