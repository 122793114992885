// Common header template for CardTemplate

import { useSearchParams } from 'react-router-dom';
import { useModal } from './ModalProvider';
import ImageBtns from './ImageBtns';
import './CardHeaderView.scss';

export default function CardHeaderView({
  imgUrl,
  imgThumbUrl,
  imgTitle,
  name,
  details,
  allowEdit,
  onDelete,
  whatToDelete,
  show,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { openModal } = useModal();

  return (
    <div className={'card-header-view' + (show ? ' show' : '')}>
      <ImageBtns
        imgUrl={imgUrl}
        imgThumbUrl={imgThumbUrl}
        imgTitle={imgTitle}
        size={128}
        clickable
      />
      <div className='info-buttons-wrap'>
        <div className='info'>
          <h4>{name}</h4>
          <div className='details'>{details}</div>
        </div>
        <div className='buttons'>
          {allowEdit && (
            <button
              className='edit small bw'
              onClick={() => setSearchParams({ edit: true }, { replace: true })}
            >
              Edit
            </button>
          )}
          {onDelete && (
            <button
              className='delete small danger'
              onClick={() =>
                openModal({
                  header: `Delete ${whatToDelete}`,
                  body: (
                    <p className='font-16'>
                      {`Are you sure you want to delete the ${whatToDelete}?`}
                    </p>
                  ),
                  buttons: [
                    {
                      text: 'Cancel',
                      className: 'bw',
                    },
                    {
                      text: 'Delete',
                      className: 'danger',
                      onClick: onDelete,
                    },
                  ],
                })
              }
            >
              Delete
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
