// Use as a wrapper to catch the clicks outside the content

import { useRef, useEffect } from 'react';

export default function GetClickOutside({ children, active, onClick }) {
  const wrapRef = useRef(null);

  const checkClickOutside = (e) => {
    if (wrapRef.current && !wrapRef.current.contains(e.target)) onClick();
  };

  useEffect(() => {
    if (active) {
      document.addEventListener('mousedown', checkClickOutside);
    } else document.removeEventListener('mousedown', checkClickOutside);

    return () => {
      document.removeEventListener('mousedown', checkClickOutside);
    };
  }, [active]);

  return (
    <div className='get-click-outside' ref={wrapRef}>
      {children}
    </div>
  );
}
