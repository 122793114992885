// Common style pattern

import './PropItemsBlock.scss';
import Spinner from './Spinner';

export default function PropItemsBlock({ spinner = false, children }) {
  return (
    <div className='prop-items-block'>
      {children}
      <Spinner show={spinner} />
    </div>
  );
}
