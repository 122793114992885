// This page is linked to the email auth link

import { useLoaderData } from 'react-router-dom';
import Layout from '../components/Layout';
import AuthCard from '../components/AuthCard';

export default function PageAuth() {
  const data = useLoaderData();
  const { token, teamName, role } = data;

  return (
    <Layout screenSized className='page-auth'>
      <AuthCard token={token} teamName={teamName} role={role} />
    </Layout>
  );
}
