// Component to show image preview with Upload, Download, and Delete buttons

import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ImageModal from './ImageModal';
import Spinner from './Spinner';
import SelectInline from './SelectInline';
import { useModal } from './ModalProvider';
import { fileSizeLimit } from '../utils/consts';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../utils/firebase';
import './ImageBtns.scss';

export default function ImageBtns({
  imgUrl,
  imgThumbUrl,
  imgTitle,
  showTitleOnThumb,
  size,
  clickable,
  downloadable,
  imgPath,
  onUploadImg,
  onDeleteImg,
  types,
}) {
  const [showImgModal, setShowImgModal] = useState(false);
  const [progress, setProgress] = useState(null);
  const [err, setErr] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [showTypesSelector, setShowTypesSelector] = useState(false);
  const [type, setType] = useState();
  const inputFileRef = useRef();
  const { openModal } = useModal();

  // Error auto-reset
  useEffect(() => {
    if (err) setTimeout(() => setErr(null), 10000);
  }, [err]);

  const onFileChoose = async (e) => {
    const file = e.target.files[0];

    if (file.type.split('/')[0] !== 'image') {
      setErr('wrong file type');
      return;
    }

    if (file.size > fileSizeLimit) {
      setErr('file size limit is 5Mb');
      return;
    }

    const storageRef = ref(storage, imgPath + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);
    setProgress(0);

    uploadTask.on(
      'state_changed',
      (state) => setProgress((state.bytesTransferred / state.totalBytes) * 100),
      (error) => {
        setErr(error.message);
        setProgress(null);
      },
      async () => {
        try {
          await onUploadImg(file, type);
          setErr(null);
          setProgress(null);
          setSpinner(true);
        } catch (error) {
          setErr(error.message || 'uploading went wrong');
          setProgress(null);
        }
      }
    );
  };

  useEffect(() => {
    setSpinner(false);
  }, [imgUrl]);

  return (
    <div className='image-btns' style={{ width: size + 60 + 'px' }}>
      {imgUrl && progress === null && (
        <div
          className={'bg-image' + (clickable ? ' clickable' : '')}
          style={{
            backgroundImage: `url(${imgThumbUrl})`,
            width: size,
            height: size,
          }}
          onClick={
            imgUrl && clickable ? () => setShowImgModal(true) : undefined
          }
        />
      )}
      {!imgUrl && progress === null && (
        <div
          className={'upload-icon-wrap' + (onUploadImg ? ' upload' : '')}
          style={{
            width: size,
            height: size,
          }}
          onClick={
            onUploadImg
              ? () => {
                  if (types) {
                    setShowTypesSelector(true);
                  } else inputFileRef.current.click();
                }
              : undefined
          }
        >
          <p className='fa-upload-icon gray-500'>{'\uf093'}</p>
        </div>
      )}
      {progress !== null && (
        <div
          className='progress-wrap'
          style={{
            width: size,
            height: size,
          }}
        >
          <div className='progress-line'>
            <div className='progress' style={{ width: progress + '%' }} />
          </div>
          <p className='font-medium'>Uploading {Math.trunc(progress)}%</p>
        </div>
      )}
      <Spinner show={spinner} />
      <label
        className={
          'image-title' + (imgTitle && showTitleOnThumb ? '' : ' no-title')
        }
      >
        {imgTitle || 'No title'}
      </label>
      <label className={'error' + (err ? ' show' : '')}>Error: {err}</label>
      <div className='btns'>
        {downloadable && imgUrl && (
          <Link
            to={imgUrl}
            target='_blank'
            onClick={(e) => e.stopPropagation()}
            className='button bw small'
          >
            Download
          </Link>
        )}
        {onUploadImg && (
          <div className='upload-wrap'>
            <button
              type='button'
              className='small bw'
              onClick={() => {
                if (types) {
                  setShowTypesSelector(true);
                } else inputFileRef.current.click();
              }}
            >
              Upload
            </button>
            {types && (
              <SelectInline
                className='select-inline-type'
                header='Choose a file type'
                options={types}
                small
                show={showTypesSelector}
                onChange={(type) => {
                  setType(type);
                  setShowTypesSelector(false);
                  inputFileRef.current.click();
                }}
                onClose={() => setShowTypesSelector(false)}
              />
            )}
          </div>
        )}
        {onDeleteImg && imgUrl && (
          <button
            type='button'
            className='small danger'
            onClick={() =>
              openModal({
                header: 'Delete image',
                body: (
                  <p className='font-16'>
                    Are you sure you want to delete the image?
                  </p>
                ),
                buttons: [
                  {
                    text: 'Cancel',
                    className: 'bw',
                  },
                  {
                    text: 'Delete',
                    className: 'danger',
                    onClick: async () => {
                      try {
                        setSpinner(true);
                        await onDeleteImg();
                        setErr(null);
                      } catch (error) {
                        setSpinner(false);
                        setErr(error.message || 'deleting went wrong');
                      }
                    },
                  },
                ],
              })
            }
          >
            Delete
          </button>
        )}
      </div>
      <input
        type='file'
        accept='image/*'
        onChange={(e) => onFileChoose(e)}
        ref={inputFileRef}
        className='file-upload-input'
      />
      <ImageModal
        show={showImgModal}
        imgUrl={imgUrl}
        title={imgTitle}
        onClose={() => setShowImgModal(false)}
        downloadable={downloadable}
        clickable
      />
    </div>
  );
}
