// Coach details interface

import { useNavigate } from 'react-router-dom';
import { useFirebase } from './FirebaseContextProvider';
import CardTemplate from './CardTemplate';
import TeamsList from './TeamsList';
import Tabs from './Tabs';
import Gallery from './Gallery';
import PropItem from './PropItem';
import PropItemsBlock from './PropItemsBlock';
import CoachCardEdit from './CoachCardEdit';
import PaymentsList from './PaymentsList';
import { userUpdate } from '../utils/firebase';
import { DocTypes } from '../utils/enums';

export default function CoachCard({ coach }) {
  const navigate = useNavigate();
  const { user } = useFirebase();

  const { id, imgUrl, imgThumbUrl, name, phone, email, docs, teams } = coach;

  const isTheUser = user.id === id;

  const onUploadImg = async (file, index, type) => {
    try {
      const newDocs = docs.map(({ image, type }) => ({ image, type }));

      const doc = { image: file.name, type: type ? type : DocTypes.OTHER };

      if (index !== undefined) {
        newDocs[index] = doc;
      } else newDocs.push(doc);

      await userUpdate({ id, docs: newDocs });
    } catch (error) {
      console.error(error.message);
    }
  };

  const onDeleteImg = async (index) => {
    try {
      const newDocs = docs
        .map(({ image, type }) => ({ image, type }))
        .filter((doc, i) => i !== index);

      await userUpdate({ id, docs: newDocs });
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <CardTemplate
      header='Coach'
      className='coach-card'
      cardBtnText={isTheUser ? undefined : 'Back'}
      cardBtnOnClick={isTheUser ? undefined : () => navigate(-1)}
      imgUrl={imgUrl}
      imgThumbUrl={imgThumbUrl}
      imgTitle={name}
      name={name}
      details={
        <PropItemsBlock>
          <PropItem prop='Phone' value={phone} />
          <PropItem prop='e-mail' value={email} />
        </PropItemsBlock>
      }
      editForm={isTheUser && <CoachCardEdit coach={coach} />}
    >
      <Tabs
        className='coach-card-tabs'
        tabs={[
          {
            title: 'Teams',
            hash: '#teams',
            component: (
              <div className='block'>
                <TeamsList idFilter={teams} />
              </div>
            ),
          },
          {
            title: 'Documents',
            hash: '#documents',
            component: (
              <div className='block'>
                <Gallery
                  images={docs.map((doc) => ({
                    title: doc.type,
                    thumbUrl: doc.imgThumbUrl,
                    url: doc.imgUrl,
                    path: `users/${id}/`,
                  }))}
                  newImgUploadPath={`users/${id}/`}
                  onUploadImg={isTheUser ? onUploadImg : undefined}
                  onDeleteImg={isTheUser ? onDeleteImg : undefined}
                  types={[
                    DocTypes.ID,
                    DocTypes.CERTIFICATE,
                    DocTypes.LICENSE,
                    DocTypes.OTHER,
                  ]}
                />
              </div>
            ),
          },
          ...(isTheUser
            ? [
                {
                  title: 'Payments',
                  hash: '#payments',
                  component: (
                    <div className='block component-wrap'>
                      <PaymentsList userId={user.id} />
                    </div>
                  ),
                },
              ]
            : []),
        ]}
      />
    </CardTemplate>
  );
}
