// Tournaments list

import { useState } from 'react';
import { useFirebase } from './FirebaseContextProvider';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { TournTeamStatuses, UsersRoles } from '../utils/enums';
import TeamTournStatusPaid from './TeamTournStatusPaid';
import Select from './Select';
import Spinner from './Spinner';
import './TournamentsList.scss';

const times = [
  'All time',
  ...(() => {
    const monthsList = [];
    for (let i = 0; i < 12; i++)
      monthsList.push(moment().add(i, 'months').format('MMMM YYYY'));
    return monthsList;
  })(),
];

function Col23({ name, location }) {
  return (
    <>
      <p className='col-2 font-medium upper'>{name}</p>
      <p className='col-3'>{location}</p>
    </>
  );
}

export default function TournamentsList({ teamId, controls }) {
  const { user, tournaments } = useFirebase();

  const locations = [
    'All locations',
    ...new Set(tournaments.map((tournament) => tournament.location)),
  ];

  const sports = [
    'All sports',
    ...new Set(tournaments.map((tournament) => tournament.sports).flat()),
  ];

  const [location, setLocation] = useState(locations[0]);
  const [sport, setSport] = useState(sports[0]);
  const [time, setTime] = useState(times[0]);
  const [spinner, setSpinner] = useState(false);

  const userIsAdmin = user.role === UsersRoles.ADMIN;
  const userIsCoach = user.role === UsersRoles.COACH;
  const userIsPlayer = user.role === UsersRoles.PLAYER;
  const addCol = teamId && (userIsAdmin || userIsCoach);

  return (
    <div className={'tournaments-list' + (addCol ? ' add-col' : '')}>
      {controls && (
        <div className='controls'>
          <ul className='filters'>
            <li>
              <Select
                options={locations}
                value={location}
                onChange={setLocation}
                small
                className='location'
              />
            </li>
            <li>
              <Select
                options={sports}
                value={sport}
                onChange={setSport}
                small
                className='sport'
              />
            </li>
            <li>
              <Select
                options={times}
                value={time}
                onChange={setTime}
                small
                className='time'
              />
            </li>
          </ul>
          <Link to='/tournaments/new?edit=true' className='button small bw'>
            Add tournament
          </Link>
        </div>
      )}
      <ul className='header tab-header'>
        <li className='col-1'></li>
        <li className='col-2'>Tournament</li>
        <li className='col-3'>Location</li>
        <li className='col-23-wrap'>Tournament, Location</li>
        <li className='col-4'>Sport</li>
        <li className='col-5'>Division</li>
        <li className='col-45-wrap'>Sport, Division</li>
        <li className='col-6'>From, To</li>
        <li className='col-456-wrap'>From - To, Sport, Division</li>
        {addCol && (
          <ul className='additional tab-header'>
            <li className='status'>Status</li>
            <li className='paid'>Paid</li>
            <li className='status-paid-wrap'>Status, Paid</li>
          </ul>
        )}
      </ul>
      {tournaments
        .filter((tourn) => {
          if (location === locations[0]) return true;
          if (tourn.location === location) return true;
          return false;
        })
        .filter((tourn) => {
          if (sport === sports[0]) return true;
          if (tourn.sports.includes(sport)) return true;
          return false;
        })
        .filter((tourn) => {
          if (time === times[0]) return true;
          if (time === moment(tourn.beginDate.toDate()).format('MMMM YYYY'))
            return true;
          if (time === moment(tourn.endDate.toDate()).format('MMMM YYYY'))
            return true;
          return false;
        })
        .filter((tourn) => {
          if (userIsAdmin) return true;
          if (userIsCoach && tourn.teams.some((team) => team.id === teamId))
            return true;
          if (
            userIsPlayer &&
            tourn.teams.some(
              (team) =>
                team.id === teamId && team.status === TournTeamStatuses.ACCEPTED
            )
          )
            return true;
          return false;
        })
        .map((tourn) => {
          const {
            id,
            imgThumbUrl,
            name,
            location,
            sports,
            genders,
            ageFrom,
            ageTo,
            beginDate,
            endDate,
          } = tourn;

          return (
            <Link className='tournament' to={'/tournaments/' + id} key={id}>
              <div
                className='bg-image wh-64'
                style={{
                  backgroundImage: `url(${imgThumbUrl})`,
                }}
              />
              <Col23 name={name} location={location} />
              <div className='col-23-wrap'>
                <Col23 name={name} location={location} />
              </div>
              <p className='col-4'>{sports.join('\n')}</p>
              <div className='col-4-8-wrap'>
                <p className='col-78'>
                  {`${moment(beginDate.toDate()).format(
                    'MMM\u00A0D,\u00A0YY'
                  )} - ${moment(endDate.toDate()).format(
                    'MMM\u00A0D,\u00A0YY'
                  )}`}
                </p>
                <p className='col-4'>{sports.join('\n')}</p>
                <p className='col-5'>{genders.join(', ')}</p>
                <p className='col-6'>
                  {ageFrom === ageTo ? ageFrom : ageFrom + ' - ' + ageTo}
                </p>
              </div>
              <div className='col-78-wrap'>
                <p className='col-7'>
                  {moment(beginDate.toDate()).format('MMM\u00A0D,\u00A0YY')}
                </p>
                <p className='col-8'>
                  {moment(endDate.toDate()).format('MMM\u00A0D,\u00A0YY')}
                </p>
              </div>
              {addCol && (
                <TeamTournStatusPaid
                  team={teamId}
                  tournament={id}
                  setSpinner={setSpinner}
                />
              )}
            </Link>
          );
        })}
      <Spinner show={spinner} />
    </div>
  );
}
