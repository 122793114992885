// Doing sign in with email link then redirect

import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Card from './Card';
import Input from './Input';
import { signInByLink, authCheckAuthByEmail } from '../utils/firebase';
import { storageEmailForSignIn } from '../utils/consts';
import { useFirebase } from './FirebaseContextProvider';
import { emailRegExp } from '../utils/misc';
import { useUserNavigate } from '../utils/hooks';
import './EmailLinkSignInCard.scss';

export default function EmailLinkSignInCard({ token }) {
  const [spinner, setSpinner] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [toInvite, setToInvite] = useState(false);
  const [err, setErr] = useState(null);
  const signInCalled = useRef(false); // We use this to prevent duplicate signIn call
  const { user } = useFirebase();
  const navigate = useNavigate();

  // if we have token, navigate to invitation after user auth
  useEffect(() => {
    if (!toInvite || !user || !token) return;
    navigate(`/invite/${token}`);
  }, [user, toInvite, token, navigate]);

  useUserNavigate({ redirect });

  // It's signInWithEmailLink wrapper
  const signIn = useCallback(
    async (email) => {
      // Preventing duplicate sign in
      if (signInCalled.current) return;
      signInCalled.current = true;

      try {
        setSpinner(true);

        // If a user has no invitation, his email should be registered already
        if (!token) {
          const checkEmailRes = await authCheckAuthByEmail({ email });
          if (!checkEmailRes?.data) {
            throw new Error(
              'Error: the email is not registered. Use an invitation link to register'
            );
          }
        }

        await signInByLink(email);

        if (token) {
          setToInvite(true);
        } else setRedirect(true);
      } catch (error) {
        if (error?.message?.includes('auth/email-already-in-use')) return; // That's because the function is calling twice for some reason and we getting the error
        setSpinner(false);
        setErr(error?.message);
        signInCalled.current = false;
      }
    },
    [token, setSpinner, setErr]
  );

  // Don't need to confirm email if we have it local storage
  useEffect(() => {
    const email = localStorage.getItem(storageEmailForSignIn);
    if (email) signIn(email);
  }, [signIn]);

  const initialValues = {
    email: '',
  };

  const validationSchema = new Yup.ObjectSchema({
    email: Yup.string()
      .matches(emailRegExp, 'Invalid email')
      .required('Required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => signIn(values.email),
  });

  return (
    <Card
      header='Confirm email'
      className='email-link-signin-card'
      spinner={spinner}
    >
      <form className='email-link-signin-form' onSubmit={formik.handleSubmit}>
        <p className='font-16'>
          Please confirm your email to finish authentication.
        </p>
        <Input
          name='email'
          placeholder='e-mail'
          {...formik.getFieldProps('email')}
          error={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : null
          }
        />
        <p className={'error-msg' + (err ? ' show' : '')}>{err}</p>
        <button type='submit'>Confirm</button>
      </form>
    </Card>
  );
}
