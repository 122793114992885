// Check box

import './CheckBox.scss';

export default function CheckBox({ label, small, value, onChange, className }) {
  return (
    <div
      className={
        'check-box pointer' +
        (small ? ' small' : '') +
        (className ? ' ' + className : '')
      }
      onClick={() => onChange(!value)}
    >
      <div className='box'>
        <span className={'fa-check' + (value ? ' visible' : '')} />
      </div>
      <p className='label'>{label}</p>
    </div>
  );
}
