// Login card

import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Card from './Card';
import Input from './Input';
import { emailRegExp } from '../utils/misc';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../utils/firebase';
import Spinner from './Spinner';
import { useUserNavigate } from '../utils/hooks';
import { useFirebase } from './FirebaseContextProvider';
import './LoginCard.scss';

const authMsgs = {
  'auth/invalid-credential': 'Invalid email or password',
};

const initialValues = {
  email: '',
  password: '',
};

export default function LoginCard() {
  const [spinner, setSpinner] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { user } = useFirebase();

  useUserNavigate({ redirect });

  useEffect(() => {
    if (user) setRedirect(true);
  }, [user]);

  const validationSchema = new Yup.ObjectSchema({
    email: Yup.string()
      .matches(emailRegExp, 'Invalid email')
      .required('Required'),
    password: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        setSpinner(true);
        await signInWithEmailAndPassword(auth, values.email, values.password);
      } catch (error) {
        setSpinner(false);
        setFieldError('email', authMsgs[error.code] ?? error.message);
      }
    },
  });

  return (
    <Card header='Login' className='login-card'>
      <form className='login-form' onSubmit={formik.handleSubmit}>
        <Input
          name='email'
          placeholder='E-mail'
          {...formik.getFieldProps('email')}
          error={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : null
          }
        />
        <Input
          name='password'
          placeholder='Password'
          type='password'
          {...formik.getFieldProps('password')}
          error={
            formik.touched.password && formik.errors.password
              ? formik.errors.password
              : null
          }
        />
        <button className='signin-btn' type='submit'>
          Sign in
        </button>
      </form>
      <Spinner show={spinner} />
    </Card>
  );
}
