// Invite page

import { useLoaderData } from 'react-router-dom';
import InviteCard from '../components/InviteCard';
import Layout from '../components/Layout';

export default function PageInvite() {
  const { token } = useLoaderData();

  return (
    <Layout screenSized className='page-invite'>
      <InviteCard token={token} />
    </Layout>
  );
}
