// Email link login card with invitation

import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Card from './Card';
import Input from './Input';
import { emailRegExp } from '../utils/misc';
import { authCheckAuthByEmail, sendEmailLink } from '../utils/firebase';
import { useFirebase } from '../components/FirebaseContextProvider';
import { storageEmailForSignIn } from '../utils/consts';
import { useUserNavigate } from '../utils/hooks';
import './AuthCard.scss';

export default function AuthCard({ token, teamName, role }) {
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [err, setErr] = useState(null);
  const { isUsersLoaded } = useFirebase();

  useEffect(() => {
    setSpinner(!isUsersLoaded);
  }, [isUsersLoaded]);

  useUserNavigate({ token });

  const initialValues = {
    email: '',
  };

  const validationSchema = new Yup.ObjectSchema({
    email: Yup.string()
      .matches(emailRegExp, 'Invalid email')
      .required('Required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const email = values.email;

      try {
        setSpinner(true);
        if (!token) {
          const checkEmailRes = await authCheckAuthByEmail({ email });
          if (!checkEmailRes?.data)
            throw new Error(
              'The email is not registered. Use an invitation link to register'
            );
        }
        await sendEmailLink({
          email,
          token,
        });
        localStorage.setItem(storageEmailForSignIn, email);
        setSpinner(false);
        setIsLinkSent(true);
      } catch (error) {
        setSpinner(false);
        setErr(error?.message);
      }
    },
  });

  return (
    <Card
      header={isLinkSent ? 'Auth link sent' : 'Get auth link'}
      className='auth-card'
      spinner={spinner}
    >
      {!isLinkSent && (
        <form className='auth-form' onSubmit={formik.handleSubmit}>
          {token && (
            <div className='invitation'>
              <p className='font-16'>
                {`You are invited to the `}
                <span className='font-medium'>{teamName}</span>
                {` team as a `}
                <span className='font-medium'>{role}</span>
              </p>
              <p className='font-16'>
                Please provide your email to get an auth link. If you registered
                already, please provide the email you are registered on.
              </p>
            </div>
          )}
          <Input
            name='email'
            placeholder='e-mail'
            {...formik.getFieldProps('email')}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null
            }
          />
          <p className={'error-msg' + (err ? ' show' : '')}>{err}</p>
          <button type='submit'>Send link</button>
        </form>
      )}
      {isLinkSent && (
        <p className='font-16'>
          The auth link is sent to{' '}
          <span className='font-medium'>{formik.values.email}</span>
          <br />
          Please check the mailbox.
        </p>
      )}
    </Card>
  );
}
