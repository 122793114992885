// Component to control InfoModal component

import { useState, useContext, createContext } from 'react';
import InfoModal from './InfoModal';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export default function ModalProvider({ children }) {
  const [show, setShow] = useState(false);
  const [onClose, setOnClose] = useState();
  const [header, setHeader] = useState('');
  const [body, setBody] = useState();
  const [buttons, setButtons] = useState([]);

  const openModal = ({ header, onClose, body, buttons = [] }) => {
    setHeader(header);
    setOnClose(onClose);
    setBody(body);
    setButtons(buttons);
    setShow(true);

    return () => setShow(false);
  };

  return (
    <ModalContext.Provider value={{ openModal }}>
      {children}
      <InfoModal
        show={show}
        onClose={() => {
          if (onClose) onClose();
          setShow(false);
        }}
        header={header}
        body={body}
        buttons={buttons.map((btn) => ({
          ...btn,
          onClick: () => {
            if (btn.onClick) btn.onClick();
            setShow(false);
          },
        }))}
      />
    </ModalContext.Provider>
  );
}
