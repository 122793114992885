// Edition form template to use with CardTemplate

import { useSearchParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import ImageBtns from './ImageBtns';
import Spinner from './Spinner';
import { getStorageUrl } from '../utils/firebase';
import './CardEditTemplate.scss';

export default function CardEditTemplate({
  children,
  className,
  id,
  imgUrl,
  imgThumbUrl,
  imgPath,
  formConfig,
  create,
  update,
  afterCreateNavPath,
}) {
  const [tmpImg, setTmpImg] = useState('');
  const [tmpImgUrl, setTmpImgUrl] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [reqErr, setReqErr] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const isNew = id === 'new';
  const edit = searchParams.get('edit');

  const onSubmit = async (values) => {
    try {
      setSpinner(true);
      if (isNew) {
        await create({ image: tmpImg, ...values });
        navigate(afterCreateNavPath, { replace: true });
      } else {
        await update({ id, ...values });
        setSearchParams({ edit: '', saved: true }, { replace: true });
      }
      setSpinner(false);
      setReqErr(null);
    } catch (error) {
      console.error(error);
      setReqErr(error.message);
      setSpinner(false);
    }
  };

  const formik = useFormik({
    ...formConfig,
    onSubmit,
  });

  useEffect(() => {
    if (edit) {
      formik.resetForm();
      formik.setValues(formConfig.initialValues);
      setReqErr(null);
    }
  }, [edit]);

  const onUploadImg = async (file) => {
    try {
      if (isNew) {
        setTmpImg(file.name);
        setTmpImgUrl(await getStorageUrl(`temp/${file.name}`));
      } else {
        await update({ id, image: file.name });
      }
    } catch (error) {
      setReqErr(error.message);
    }
  };

  const onDeleteImg = async () => {
    try {
      if (isNew) {
        setTmpImg('');
        setTmpImgUrl(null);
      } else {
        await update({ id, image: '' });
      }
    } catch (error) {
      setReqErr(error.message);
    }
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={'card-edit-template' + (className ? ' ' + className : '')}
    >
      <ImageBtns
        imgUrl={isNew ? tmpImgUrl : imgUrl}
        imgThumbUrl={isNew ? tmpImgUrl : imgThumbUrl}
        imgTitle='Main image'
        size={128}
        imgPath={isNew ? 'temp/' : imgPath}
        onUploadImg={onUploadImg}
        onDeleteImg={onDeleteImg}
        clickable
      />
      <div className='children-btns'>
        <div className='children-wrap'>{children(formik)}</div>
        <label className={'request-error' + (reqErr ? ' show' : '')}>
          {reqErr}
        </label>
        <div className='save-cancel-btns'>
          <button
            className='bw'
            onClick={() => setSearchParams({ edit: '' }, { replace: true })}
            type='button'
          >
            Cancel
          </button>
          <button type='submit'>Save</button>
        </div>
      </div>
      <Spinner show={spinner} />
    </form>
  );
}
