// Common card template for tournament card, team card, player card, etc

import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Card from './Card';
import CardHeaderView from './CardHeaderView';
import Spinner from './Spinner';
import './CardTemplate.scss';

export default function CardTemplate({
  header,
  className,
  cardBtnText,
  cardBtnOnClick,
  imgUrl,
  imgThumbUrl,
  imgTitle,
  name,
  details,
  children,
  editForm,
  onDelete,
  afterDeleteNavPath,
}) {
  const [editHeight, setEditHeight] = useState();
  const [viewHeight, setViewHeight] = useState();
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const edit = searchParams.get('edit');
  const saved = searchParams.get('saved');

  useEffect(() => {
    if (!edit && !name && !saved)
      setSearchParams({ edit: true }, { replace: true });
  }, [name, edit, saved]);

  const deleteWrap = onDelete
    ? async () => {
        try {
          setSpinner(true);
          await onDelete();
          navigate(afterDeleteNavPath);
          setSpinner(false);
        } catch (error) {
          console.error(error.message);
          setSpinner(false);
        }
      }
    : undefined;

  return (
    <Card
      header={header}
      className={'card-template ' + className}
      btnText={cardBtnText}
      onClick={cardBtnOnClick}
      btnLeftArrow
    >
      <div className='block'>
        <div
          className='header'
          style={{
            maxHeight: edit ? editHeight ?? 'unset' : viewHeight ?? 'unset',
          }}
        >
          <div
            className='view-wrap'
            ref={(ref) => {
              if (ref?.offsetHeight) setViewHeight(ref.offsetHeight);
            }}
          >
            <CardHeaderView
              imgUrl={imgUrl}
              imgThumbUrl={imgThumbUrl}
              imgTitle={imgTitle}
              name={name}
              details={details}
              allowEdit={!!editForm}
              onDelete={deleteWrap}
              whatToDelete={header.toLowerCase()}
              show={!edit}
            />
          </div>
          <div
            className={'edit-wrap' + (edit ? ' show' : '')}
            ref={(ref) => {
              if (ref?.offsetHeight) setEditHeight(ref.offsetHeight);
            }}
          >
            {editForm}
          </div>
          <Spinner show={spinner} />
        </div>
      </div>
      <div className='children-wrap'>
        {children}
        <div className={'cover' + (edit || spinner ? ' show' : '')} />
      </div>
    </Card>
  );
}
