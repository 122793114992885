// Teams list

import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useFirebase } from './FirebaseContextProvider';
import { UsersRoles } from '../utils/enums';
import TeamTournStatusPaid from './TeamTournStatusPaid';
import Select from './Select';
import Spinner from './Spinner';
import './TeamsList.scss';

export default function TeamsList({ tournamentId, controls, idFilter }) {
  const { user, users, teams } = useFirebase();

  const locations = [
    'All locations',
    ...new Set(teams.map((team) => team.location)),
  ];

  const sports = ['All sports', ...new Set(teams.map((team) => team.sport))];

  const [location, setLocation] = useState(locations[0]);
  const [sport, setSport] = useState(sports[0]);
  const [spinner, setSpinner] = useState(false);

  const userIsAdmin = user.role === UsersRoles.ADMIN;
  const userIsCoach = user.role === UsersRoles.COACH;
  const userIsPlayer = user.role === UsersRoles.PLAYER;
  const addCol = tournamentId && userIsAdmin;

  return (
    <div className={'teams-list' + (addCol ? ' add-col' : '')}>
      {controls && (
        <div className='controls'>
          <ul className='filters'>
            <li>
              <Select
                options={locations}
                value={location}
                onChange={setLocation}
                small
                className='location'
              />
            </li>
            <li>
              <Select
                options={sports}
                value={sport}
                onChange={setSport}
                small
                className='sport'
              />
            </li>
          </ul>
          <Link to='/teams/new?edit=true' className='button small bw'>
            Add team
          </Link>
        </div>
      )}
      <ul className='header tab-header'>
        <li className='col-1'></li>
        <li className='col-2'>Team</li>
        <li className='col-3'>Location</li>
        <li className='col-23'>Team, Location</li>
        <li className='col-4'>Sport</li>
        <li className='col-5'>Division</li>
        <li className='col-6'>
          Players
          <br />
          number
        </li>
        <li className='col-456'>Sport, Division, Players number</li>
        {addCol && (
          <ul className='additional tab-header'>
            <li className='status'>Status</li>
            <li className='paid'>Paid</li>
            <li className='status-paid-wrap'>Status, Paid</li>
          </ul>
        )}
      </ul>
      {teams
        .filter((team) => {
          if (location === locations[0]) return true;
          if (team.location === location) return true;
          return false;
        })
        .filter((team) => {
          if (sport === sports[0]) return true;
          if (team.sport === sport) return true;
          return false;
        })
        .filter((team) => {
          if (!idFilter) return true;
          if (idFilter.includes(team.id)) return true;
          return false;
        })
        .map((team) => {
          const { id, name, imgThumbUrl, location, sport, age, gender } = team;
          const isDisabled =
            userIsPlayer || (userIsCoach && !user.teams.includes(id));
          const playersNum = users
            .filter((user) => user.role === UsersRoles.PLAYER)
            .reduce((num, item) => num + (item.team === id ? 1 : 0), 0);

          return (
            <Link
              to={'/teams/' + id}
              className={'team' + (isDisabled ? ' disabled' : '')}
              onClick={isDisabled ? (e) => e.preventDefault() : undefined}
              key={id}
            >
              <div
                className='bg-image wh-64'
                style={{
                  backgroundImage: `url(${imgThumbUrl})`,
                }}
              />
              <p className='col-2 font-medium upper'>{name}</p>
              <p className='col-3'>{location}</p>
              <p className='col-23'>
                {name}
                <br />
                {location}
              </p>
              <p className='col-4'>{sport}</p>
              <p className='col-5'>{`${gender},\u00A0${age}`}</p>
              <p className='col-6'>{playersNum + ' players'}</p>
              <p className='col-456'>
                {sport}, {`${gender},\u00A0${age}`}
                <br />
                {playersNum + ' players'}
              </p>
              {addCol && (
                <TeamTournStatusPaid
                  team={id}
                  tournament={tournamentId}
                  setSpinner={setSpinner}
                />
              )}
            </Link>
          );
        })}
      <Spinner show={spinner} />
    </div>
  );
}
