// Component to show questions or information by modal window way

import Card from './Card';
import './InfoModal.scss';

export default function InfoModal({
  show,
  onClose,
  header,
  body,
  buttons = [],
}) {
  return (
    <div className={'info-modal modal' + (show ? ' show' : '')}>
      <Card header={header} className='info-card' onClick={onClose} btnCross>
        {body}
        {buttons.length > 0 && (
          <div className='btn-block'>
            {buttons.map((button, i) => (
              <button
                className={button.className ? ' ' + button.className : ''}
                onClick={button.onClick}
                key={i}
              >
                {button.text}
              </button>
            ))}
          </div>
        )}
      </Card>
    </div>
  );
}
