// This page user sees by following an email auth link

import { useLoaderData } from 'react-router-dom';
import Layout from '../components/Layout';
import EmailLinkSignInCard from '../components/EmailLinkSignInCard';

export default function PageEmailLinkSignIn() {
  const { token } = useLoaderData();

  return (
    <Layout screenSized className='page-email-link-signin'>
      <EmailLinkSignInCard token={token} />
    </Layout>
  );
}
