// Tournament page

import { useLoaderData } from 'react-router-dom';
import Layout from '../components/Layout';
import TournamentCard from '../components/TournamentCard';
import Spinner from '../components/Spinner';

export default function PageTournament() {
  const tournament = useLoaderData();

  return (
    <Layout className='page-tournament'>
      {!tournament.loading && <TournamentCard tournament={tournament} />}
      <Spinner show={tournament.loading} />
    </Layout>
  );
}
